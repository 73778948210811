import "@scss/kbd.scss";

import "./customPW";
import "./jokerGame";
import "./components/statesMap";
import LazyLoad from "vanilla-lazyload";
import { Tooltip } from "bootstrap";
import $ from "jquery";
import { createApp } from "vue";

import EventsComponent from "../vue/EventsComponent.vue";

const main = async () => {
    const app = createApp({
        components: {
            EventsComponent,
        },
    });
    const root = app.mount("#events");
    return root;
};

main().then((root) => {});

$(function () {
    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy",
    });
});

var stickyNavTop = "";
if ($(".menu-wrapper").length) {
    stickyNavTop = $(".menu-wrapper").offset().top;
}

function sickyheader() {
    var scrollTop = $(window).scrollTop();

    if (scrollTop > stickyNavTop) {
        if ($(".menu-wrapper").hasClass("sticky")) return;
        $(".menu-wrapper").addClass("sticky");
    } else {
        $(".menu-wrapper").removeClass("sticky");
    }
}

$(document).ready(function () {
    if ($(".menu-wrapper").length) {
        sickyheader();
    }

    $(window).on("scroll", function (e) {
        sickyheader();
    });

    var url = new URL(window.location.href);
    var section = url.searchParams.get("section");

    if (section) {
        $(window).scrollTop(
            $("#" + section).offset().top -
                ($(".menu-wrapper").outerHeight() + 100)
        );
    }

    if ($("#countDown").length) {
        // Set the date we're counting down to
        var countDownDate = new Date("April 1, 2021 00:00:00").getTime();

        // Update the count down every 1 second
        var x = setInterval(function () {
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                document.getElementById("countDown").innerHTML = "";
            } else {
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                var minutes = Math.floor(
                    (distance % (1000 * 60 * 60)) / (1000 * 60)
                );
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Display the result in the element with id="countDown"
                var countHtml =
                    '<div class="row"><div class="col-3 p-1"><h2 class="redCircle">' +
                    days +
                    "</h2><div>Days</div></div>";
                countHtml +=
                    '<div class="col-3 p-1"><h2 class="redCircle">' +
                    hours +
                    "</h2><div>Hours</div></div>";
                countHtml +=
                    '<div class="col-3 p-1"><h2 class="redCircle">' +
                    minutes +
                    "</h2><div>Minutes</div></div>";
                countHtml +=
                    '<div class="col-3 p-1"><h2 class="redCircle">' +
                    seconds +
                    "</h2><div>Seconds</div></div></div>";
                document.getElementById("countDown").innerHTML = countHtml;
            }
        }, 1000);
    }

    var current_width = $(window).width();
    // executes when HTML-Document is loaded and DOM is ready
    $('[data-toggle="offcanvas"], #navToggle').on("click", function () {
        $(".offcanvas-collapse").toggleClass("open");
    });

    $(".closeNav").on("click", function (e) {
        e.preventDefault();
        $(".offcanvas-collapse").removeClass("show");
        $(".offcanvas-collapse").removeClass("open");
    });

    $(document).keyup(function (e) {
        if (e.key === "Escape") {
            // escape key maps to keycode `27`
            $(".offcanvas-collapse").removeClass("show");
            $(".offcanvas-collapse").removeClass("open");
        }
    });

    $('[data-bs-toggle="tooltip"]').on("mouseenter", function () {
        let element = this;
        const tooltip = new Tooltip(element);
    });

    $(".resetMap").click(function () {
        map.setCenter({ lat: 39.9, lng: -97.1 });
        map.setZoom(4.5);
    });

    if ($("form.pledgeform").length) {
        $(".pledgeform").submit(function (e) {
            e.preventDefault();
            var error = false;
            if ($(this).find('input[name="first_name"]').val() == "") {
                error = true;
                alert("First Name is required");
            }
            if ($(this).find('input[name="email"]').val() == "") {
                error = true;
                alert("Email is required");
            }
            if (!error) {
                var form = $(this);
                var url = form.attr("action");
                $.ajax({
                    type: "POST",
                    crossDomain: true,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                    url: url,
                    data: form.serialize(),
                    success: function (data) {
                        var response = $.parseJSON(data);

                        if (response.response == true) {
                            //form.append('<div class="alert alert-success alert-dismissible fade show" role="alert">Thank you for taking the pledge!<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
                            //window.location.href = "/thank-you/pledge";
                            window.location.href = "/confirmation-join";
                        } else {
                            form.append(
                                '<div class="alert alert-danger alert-dismissible fade show" role="alert">There was an error, please try again later.<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>'
                            );
                        }
                    },
                });
            }
        });
    }

    $(".navbar .dropdown > a.dropdown-toggle").on("click", function (e) {
        e.preventDefault();
        if (e.target.href && e.target.href != "" && e.target.href != "#") {
            location.href = this.href;
        }
    });

    $(".navbar .dropdown .mobile-dropdown-toggle").on("click", function (e) {
        e.preventDefault();
        var $clickedDropdown = $(this).find("i");
        if ($clickedDropdown.hasClass("fa-minus")) {
            $clickedDropdown.parent().parent().find(".dropdown-menu").hide();
            $clickedDropdown.addClass("fa-plus");
            $clickedDropdown.removeClass("fa-minus");
        } else {
            $(".navbar .dropdown .mobile-dropdown-toggle").each(function () {
                $(this).parent().find(".dropdown-menu").hide();
                $(this).find("i").addClass("fa-plus");
                $(this).find("i").removeClass("fa-minus");
            });
            $clickedDropdown.parent().parent().find(".dropdown-menu").show();
            $clickedDropdown.removeClass("fa-plus");
            $clickedDropdown.addClass("fa-minus");
        }
    });

    $(".registerAnEventBtn").on("click", function (e) {
        e.preventDefault();
        console.log("boooo");
        $(".registerAnEventForm").removeClass("d-none");
    });
    // }

    var str = $(".fad").html();
    if (str) {
        str = str.replace("&nbsp;", "");
        $(".fad").html(str);
    }

    var xTriggered = 0;
    $("textarea.story").on("keyup", function (event) {
        var currentText = $(this).val();
        console.log(currentText.length);
        xTriggered = currentText.length;
        var lenLimit = 140;
        console.log(xTriggered);
        if (xTriggered <= lenLimit) {
            $("#charNum").text(lenLimit - xTriggered);
        }
    });

    // function countChar(val) {
    //     var len = val.value.length;
    //     var lenLimit = 140;
    //     if (len > lenLimit) {
    //         val.value = val.value.substring(0, lenLimit);
    //     } else {
    //         $("#charNum").text(lenLimit - len);
    //     }
    // }
});

window.onload = function () {
    $(function () {
        var formUrl = $("#myAccountWrapper").attr("data-formurl");
        loadForm(formUrl);
    });
};

function loadForm(formUrl) {
    var iframe = document.createElement("iframe");
    var iframeCounter = 0;
    var form_height = "580px";
    iframe.id = "accountiFrame";
    iframe.src = formUrl;
    iframe.height = form_height;
    iframe.class = "embedded_form";
    iframe.frameBorder = "0";
    iframe.width = "100%";
    iframe.onload = function (element) {
        var iframeRealHeight = $(element)[0].height;
        if (iframeRealHeight == "" || iframeRealHeight == undefined) {
            iframeRealHeight = element.path[0].height;
        }
        $("#myAccountWrapper").find("iframe").attr("height", iframeRealHeight);
        if (iframeCounter > 0) {
            console.log("scrollTop");
            $(window).scrollTop($("iframe").offset().top - 200);
        }
        iframeCounter++;
    };

    $("#myAccountWrapper").html(iframe);
    $("#myAccountWrapper iframe").bind("DOMSubtreeModified", function () {
        this.addEventListener("touchstart", {});
        this.addEventListener("touchstart", {});
    });
}

window.addEventListener(
    "message",
    function (e) {
        var $iframe = $("#accountiFrame");
        if (e.data != null && e.data !== "") {
            if (Array.isArray(e.data)) {
                var eventName = e.data[0];
                var data = e.data[1];
                switch (eventName) {
                    case "setHeight":
                        console.log("setHeight");
                        console.log(data);
                        $iframe.attr("height", data + "px");
                        // console.log(data);
                        // console.log('listener height');
                        break;
                    case "refreshPage":
                        console.log("refreshPage");
                        if (data != "" && data != null) {
                            window.location = data;
                        }
                    case "username":
                        var d = new Date();
                        var exdays = 730;
                        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
                        var expires = "expires=" + d.toUTCString();

                        console.log("username");

                        if (data != "" && data != null) {
                            document.cookie =
                                "userLoggedin = " +
                                true +
                                ";expires=" +
                                expires +
                                ";";
                        } else {
                            document.cookie = "userLoggedin = " + null;
                        }
                }
            } else {
                document.cookie = "userLoggedin = " + null;
            }
        }
    },
    false
);

$(".nav-item .expand-menu").on("click", function (e) {
    if (!$(this).parent().hasClass("open")) {
        $(".nav-item.open").removeClass("open");
        $(this).parent().addClass("open");
    } else {
        $(".nav-item.open").removeClass("open");
    }
});

$(window).keyup(function (e) {
    let input = e.target;
    if ($(input).attr("name") == "MobilePhone" && $(input).val()) {
        $(".SmsLegalDisclaimer").show();
    } else if ($(input).attr("name") == "MobilePhone") {
        $(".SmsLegalDisclaimer").hide();
    }
});

const observer = new MutationObserver(function (mutationList) {
    mutationList.forEach(function (mutation) {
        if (
            mutation.type === "attributes" &&
            mutation.attributeName === "class"
        ) {
            $(
                ".ngp-form.horizontal .at-row.MobilePhone .EmailAddress"
            ).prependTo(".at-row.PostalCode");
            $(".ngp-form.horizontal .at-row.PostalCode").addClass(
                "EmailAddress"
            );
            $(
                ".ngp-form.horizontal .at-row.MobilePhone .EmailAddress"
            ).remove();
            $(
                ".ngp-form.horizontal .at-row.PostalCode.EmailAddress"
            ).removeClass("at-row-solo");
            $(".ngp-form.horizontal .at-row.MobilePhone").removeClass(
                "EmailAddress"
            );
            $(".ngp-form.horizontal .at-row.MobilePhone").addClass(
                "at-row-solo"
            );
        }
    });
});

if ($(".ngp-form.horizontal").length) {
    const form = document.querySelector(".ngp-form");
    const options = {
        attributes: true,
    };

    observer.observe(form, options);
}

$(".button-video, .play-profile-video").on("click", function () {
    let url = $(this).data("src");
    let id = $(this).data("bs-target");

    $(id + " iframe").attr("src", url);

    const modal = document.getElementById($(this).data("target"));

    modal.addEventListener("shown.bs.modal", (event) => {
        $(id + " iframe").attr("src", url + "&autoplay=1");
    });
    modal.addEventListener("hidden.bs.modal", (event) => {
        $(id + " iframe").removeAttr("src");
    });
    $(document).on("keydown", function (e) {
        if (e.originalEvent.key == "Escape") {
            $(".modal-header .btn-close").trigger("click");
            $(document).off("keydown");
        }
    });
});