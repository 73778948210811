<template>
	<div class="container">
		<div class="row">
			<form id="eventsSearch" class="filters mb-3">
				<div class="row justify-content-between">
					<div class="col me-md-2 mb-2 mb-md-0 p-0">
						<input
							type="text"
							class="form-control rounded"
							id="keyboardSearch"
							placeholder="Filter by name, description, location or organizer"
							@input="debounceInput()"
						/>
						<button
							type="button"
							class="keyboardSearch filter btn bg-transparent shadow-none d-none"
							@click="deleteCriteria('keyboardSearch')"
						>
							<i class="fa fa-times"></i>
						</button>
					</div>
					<div class="col-md-3 p-0">
						<input
							type="text"
							class="form-control rounded"
							id="state"
							placeholder="State"
							@focus="generateSuggestions('state')"
							@input="filterSuggestions('state')"
						/>
						<button
							type="button"
							class="state filter btn bg-transparent shadow-none d-none"
							@click="deleteCriteria('state')"
						>
							<i class="fa fa-times"></i>
						</button>
						<ul class="suggestions" v-if="searchTopic == 'state'">
							<li
								v-for="(sug, index) in suggestions"
								:key="'state' + index"
								@click="selectItem(sug, 'state')"
							>
								{{ sug.title }}
							</li>
						</ul>
					</div>
				</div>
			</form>
		</div>

		<div class="row">
			<div
				class="col-12 p-0 mb-3 d-flex justify-content-between align-items-center"
			>
				<p>
					{{
						orderedEvents.length == 0
							? "No events found."
							: orderedEvents.length + " events"
					}}
				</p>

				<button
					type="button"
					class="btn btn-light export-events"
					@click="exportEvents()"
				>
				<i class="fas fa-file-csv"></i>
					Export Events
				</button>
			</div>
			<div class="table-responsive p-0">
				<table class="events table table-lg table-striped table-hover">
					<thead>
						<tr>
							<th
								scope="col"
								@click="sort('eventTitle')"
								class="sorter eventTitle"
							>
								Event Name
							</th>
							<th
								scope="col"
								@click="sort('eventLocation')"
								class="sorter eventLocation"
							>
								Location
							</th>
							<th
								style="width: 15%"
								@click="sort('eventDate')"
								class="sorter eventDate"
							>
								Event Date
							</th>
							<th
								scope="col"
								@click="sort('organizer')"
								class="sorter organizer"
							>
								Organizer
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							class="event"
							v-for="event in paginated"
							:key="event.id"
							@click="showModalEvent(event.id)"
						>
							<td class="eventTitle">{{ event.eventTitle }}</td>
							<td>{{ event.eventLocation }}</td>
							<td>{{ formattedDate(event.eventDate) }}</td>
							<td class="organizer">
								{{ event.organizer.name }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div
			class="row justify-content-between align-items-start"
			v-if="isLoading == false || resultCount > paginated.length"
		>
			<ul class="pagination justify-content-center">
				<li class="page-item" v-if="currentPage != 0">
					<a class="page-link" @click="setPage(currentPage - 1)"
						><i class="fas fa-chevron-left"></i
					></a>
				</li>

				<li class="page-item" v-for="n in [2, 1, 0]" :key="n + 'b'">
					<a
						class="page-link"
						href="#"
						@click="setPage(currentPage - n)"
						v-if="n <= currentPage && n > 0"
						>{{ currentPage + 1 - n }}</a
					>
				</li>

				<li class="page-item active">
					<a class="page-link" href="#">{{ currentPage + 1 }}</a>
				</li>

				<li class="page-item" v-for="n in 3" :key="n + 'a'">
					<a
						class="page-link"
						href="#"
						v-if="currentPage + n + 1 <= totalPages"
						@click="setPage(currentPage + n)"
						>{{ currentPage + n + 1 }}</a
					>
				</li>

				<li class="page-item" v-if="currentPage + 1 < totalPages">
					<a class="page-link" @click="setPage(currentPage + 1)"
						><i class="fas fa-chevron-right"></i
					></a>
				</li>
			</ul>
		</div>

		<div
			v-if="modalEvent || showingModal"
			id="modal-event"
			:data-event="modalEvent.id"
		>
			<div id="modal-container">
				<div class="modal-actions">
					<i class="fa fa-times close-modal"></i>
				</div>
				<div class="modal-data">
					<div v-if="modalEvent.eventTitle" class="item">
						<strong>{{ modalEvent.eventTitle }}</strong>
					</div>
					<div v-if="modalEvent.eventDate" class="item">
						{{ formattedDate(modalEvent.eventDate) }}
					</div>
					<div v-if="modalEvent.eventLocation" class="item">
						{{ modalEvent.eventLocation }}
					</div>
					<div v-if="modalEvent.body" class="item">
						{{ modalEvent.body }}
					</div>
					<div v-if="modalEvent.organization" class="item">
						{{ modalEvent.organization }}
					</div>
					<div v-if="modalEvent.participants" class="item">
						{{ modalEvent.participants }} participants
					</div>
					<div v-if="modalEvent.map[0] == 1" class="item">
						<i class="fas fa-check text-success"></i>
						<span>Show on event map</span>
					</div>

					<div class="user">
						<p class="text-right">
							{{ modalEvent.organizer.name }}<br />
							<span>{{ modalEvent.organizer.email }}</span
							><br />
							{{ modalEvent.organizer.zip }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Vue2Filters from "vue2-filters";
import $ from "jquery";

const queryData = {
	query: `
		{
			entries(section: "events", type: "localEvent") {
				... on events_localEvent_Entry {
				id
				eventTitle
				eventDate
				eventLocation
				organization: organizationsOrGroupsHosting
				eventVenue
				body
				participants: howManyParticipants
				map: AppearOnInteractiveTDTEventMap
				organizer {
					... on UserInterface {
					firstName
					lastName
					email
					}
				}
				}
			}
		}
	`,
};
export default {
	mixins: [Vue2Filters.mixin],

	data() {
		return {
			events: [],
			orderedEvents: [],
			states: [],
			suggestions: [],
			searchTopic: "",
			isSearch: false,
			isLoading: false,
			order: 1,
			sortKey: "",
			page: 1,
			index: 0,
			currentPage: 0,
			resultCount: 0,
			itemsPerPage: 100,
			modalEvent: null,
			showingModal: false,
		};
	},

	computed: {
		paginated() {
			this.resultCount = this.orderedEvents.length;
			if (this.currentPage >= this.totalPages) {
				this.currentPage = Math.max(0, this.totalPages - 1);
			}
			this.index = this.currentPage * this.itemsPerPage;
			return this.orderedEvents.slice(
				this.index,
				this.index + this.itemsPerPage
			);
		},

		totalPages() {
			return Math.ceil(this.resultCount / this.itemsPerPage);
		},
	},

	methods: {
		search() {
			var state = $("#state").data("state"),
				keyboards = document.getElementById("keyboardSearch"),
				events = this.events;

			if (state) {
				events = this.filterBy(events, state, "state");
				$("button.state").removeClass("d-none");
			}
			if (keyboards.value) {
				events = this.filterBy(events, keyboards.value);
				$("button.keyboardSearch").removeClass("d-none");
			}
			this.orderedEvents = events;
			this.isSearch = true;
		},

		generateSuggestions(input) {
			this.searchTopic = input;
			this.suggestions =
				input == "state" ? this.states : this.orderedEvents;
		},

		filterSuggestions(input) {
			if (input != "state") return;
			const key = document.getElementById(input).value;
			$("button.state").removeClass("d-none");
			this.suggestions = this.filterBy(this.states, key);
		},

		selectItem(key, input) {
			document.getElementById(input).value = key.title;
			$("#" + input).data("state", key.code);
			this.search();
		},

		closeSuggestions(e) {
			if (!document.getElementById("state").contains(e.target)) {
				this.searchTopic = "";
				document.removeEventListener("click", this.closeSuggestions);
			}
		},

		deleteCriteria(input) {
			document.getElementById(input).value = "";
			$("#" + input).removeData(input);

			$("button." + input).addClass("d-none");
			this.search();
		},

		sort(column) {
			$(".sorter > i.fas").remove();
			if (this.sortKey == column && this.order == 1) {
				this.order = -1;
				$(".sorter." + column).append(
					'<i class="fas fa-sort-down"></i>'
				);
			} else {
				this.order = 1;
				this.sortKey = column;
				$(".sorter." + column).append('<i class="fas fa-sort-up"></i>');
			}
			this.orderedEvents = this.orderBy(
				this.orderedEvents,
				column,
				this.order
			);
		},

		formattedDate(date) {
			return moment(date, "YYYY/MM/DD hh:mm").format("MMMM DD, YYYY");
		},

		setPage(pageNumber) {
			this.currentPage = pageNumber;
		},

		debounceInput() {
			setTimeout(() => {
				this.search();
			}, 400);
		},

		showModalEvent(id) {
			const event = this.find(this.events, id, "id");
			this.modalEvent = event[0];
			this.showingModal = true;
		},

		closeModalEvent(e) {
			var element = $(e.target).parent();
			if (!$(element).hasClass("event")) {
				if (
					!document
						.getElementById("modal-container")
						.contains(e.target) ||
					$(e.target).hasClass("close-modal")
				) {
					this.modalEvent = null;
					this.showingModal = false;
					document.removeEventListener("click", this.closeModalEvent);
				}
			}
		},

		exportEvents() {
			let entries = "";
			this.orderedEvents.map((event) => {
				entries += event.id + "e";
			});

			window.location = "/getStaffEvents/" + entries.substring(0, entries.length - 1);
		},
	},

	watch: {
		searchTopic(searchTopic) {
			if (searchTopic) {
				document.addEventListener("click", this.closeSuggestions);
			}
		},
		showingModal(showingModal) {
			if (showingModal) {
				document.addEventListener("click", this.closeModalEvent);
			}
		},
	},

	created() {
		axios.post("/api", queryData).then((res) => {
			var events = res.data.data.entries.map(function (event) {
				event.eventLocation = event.eventLocation.replace(
					/United States/,
					""
				);

				event.organizer = {
					name:
						event.organizer[0].firstName +
						" " +
						event.organizer[0].lastName,
					email: event.organizer[0].email,
					zip: event.eventLocation.split(", ")[1].split(" ")[1],
				};

				event.state = event.eventLocation.split(", ")[1].split(" ")[0];
				return event;
			});
			this.events = events;
			this.orderedEvents = events;
		});
		axios.get("/getUsStates").then((res) => {
			this.states = res.data;
		});
	},
};
</script>
<style lang="scss">
.suggestions {
	margin-top: 50px;
	max-height: 240px;
	overflow-y: scroll;
	position: absolute;
	z-index: 99;
	background: #fff;
	border-radius: 0px 0px 5px 5px;
	width: 100%;
	padding: 0;
	box-shadow: 0 10px 10px rgba(#000, 0.3);
	li {
		list-style: none;
		padding: 5px 10px;
		cursor: pointer;
		&:hover {
			color: #fff;
			background-color: #cbd1d6;
		}
	}
}
button.filter {
	margin-left: -40px;
	z-index: 100;
	color: #cbd1d6;
	&:hover {
		color: #dc3545;
	}
}
.events .event {
	cursor: pointer;
}
#modal-event {
	background: rgba(#000, 0.3);
	position: fixed;
	z-index: 99999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	#modal-container {
		width: 40%;
		background: #fff;
		border-radius: 5px;
		.modal-actions {
			padding: 20px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			color: #cbd1d6;
			.fa-times:hover {
				color: #dc3545;
				cursor: pointer;
			}
		}
		.modal-data {
			padding: 0 20px 20px;
			.item {
				margin-bottom: 15px;
			}
		}
	}
}
.sorter {
	cursor: pointer;
	border-top: none !important;
	&:hover {
		background: rgba(#000, 0.075);
	}
}
td.eventTitle,
td.organizer,
.export-events {
	color: #dc3545;
}
.user p {
	span {
		color: #dc3545;
	}
}

</style>
