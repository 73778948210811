import $ from "jquery";
$(document).ready(function () {
    var powerWallForm = $(".powerwall").html();
    $(".powerwall").remove();
    $(".powerWallForm").html(powerWallForm);
    $("#pwForm").on("submit", function (e) {
        if ($("#story").val() == "") {
            $(".invalid-feedback").addClass("d-none");
            $(".invalid-feedback").removeClass("d-block");
            $(".invalid-story").removeClass("d-none");
            $(".invalid-story").addClass("d-block");
        } else {
            $("#submitPWbtn button").attr("disabled", "disabled");
            $("#submitPWbtn button").html(
                '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Loading...</span>'
            );
          
        }
    });
});