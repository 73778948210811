import $ from "jquery";
import * as bootstrap from "bootstrap";
var images = [];
class JokerGame {
    constructor() {
        this.cardsRoute = "https://assets.takedowntobacco.org/jokercards/";
        this.timer = null;
        this.deck = this.createDeck();
    }

    changeCard() {
        let card = Math.floor(Math.random() * (this.deck.length - 1)),
            cardName = this.deck[card];

        this.deck.splice(card, 1);

        $(".img-deck").attr("src", this.cardsRoute + cardName + ".png");
        $(".img-deck").attr("alt", cardName);

        if (cardName == "joker") this.machineClick();
    }

    createDeck() {
        let types = ["club", "diamond", "heart", "spade"];
        let deck = [];

        for (let i = 0; i < types.length; i++) {
            let prefix = types[i];
            deck.push(prefix + "_king");
            for (let j = 0; j < 10; j++) {
                var cardName = prefix + "_" + (j + 1 == 1 ? "ace" : j + 1);

                if (j == 3) {
                    deck.push(prefix + "_queen");
                } else if (j == 6 || j + 3 == 12) {
                    deck.push("joker");
                }

                deck.push(cardName);
            }
            deck.push(prefix + "_jack");
        }

        return deck;
    }

    machineClick() {
        // let s = 600 + Math.floor(Math.random() * 4) * 100;
        let s = 1200;

        game.timer = setTimeout(() => {
            $(".deck").click();
        }, s);

        return;
    }

    preload(cards) {
        for (var i = 0; i < cards.length; i++) {
            images[i] = new Image();
            images[i].src = this.cardsRoute + cards[i] + ".png";
        }
    }
}
$(function () {
    if (window.location.pathname == "/games") {
        var game = new JokerGame();
        game.preload(game.deck);

        $(".userdeck").click(function (e) {
            if (game.deck.length == 0) {
                game = new JokerGame();
                return;
            }

            if ($(".img-deck").attr("alt") == "joker") {
                e.preventDefault();
            } else {
                game.changeCard();
            }
        });

        $(".deck").click(function (e) {
            e.preventDefault();

            if ($(".img-deck").attr("alt") == "joker") {
                if (e.originalEvent) {
                    clearTimeout(game.timer);
                    game.timer = null;
                    $("#jokerGameModal")
                        .find(".modal-jtitle.win")
                        .removeClass("d-none");
                    $("#jokerGameModal")
                        .find(".alert-text.win")
                        .removeClass("d-none");
                } else {
                    $("#jokerGameModal")
                        .find(".modal-jtitle.lose")
                        .removeClass("d-none");
                    $("#jokerGameModal")
                        .find(".alert-text.lose")
                        .removeClass("d-none");
                }

                let modal = new bootstrap.Modal(
                    document.getElementById("jokerGameModal")
                );

                modal.show();

                document
                    .getElementById("jokerGameModal")
                    .addEventListener("hidden.bs.modal", function () {
                        $("#jokerGameModal")
                            .find(".modal-jtitle")
                            .addClass("d-none");
                        $("#jokerGameModal")
                            .find(".alert-text")
                            .addClass("d-none");
                        game.changeCard();
                    });

                if (game.deck.length == 0) {
                    game = new JokerGame();
                    return;
                }
            }
        });
    }
});
